import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_select, {
      modelValue: _ctx.element_id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.element_id) = $event)),
      name: "shipload",
      class: _normalizeClass(["custom-select w-100 p-0", !_ctx.multiselect ? 'chevron' : '']),
      multiple: _ctx.multiselect,
      "collapse-tags": _ctx.collapse,
      size: _ctx.size,
      clearable: "",
      placeholder: `${_ctx.$t('iselect')}`,
      filterable: "",
      remote: "",
      "remote-show-suffix": "",
      "reserve-keyword": "",
      "remote-method": _ctx.selectShipload,
      loading: _ctx.inputShipload.loading,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:shipload_id', $event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputShipload.options, (shipload) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: shipload.id,
            label: `${shipload.id} ${shipload.equipment_involved.equipment_supplier}`,
            value: shipload.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(`${shipload.id} ${shipload.equipment_involved.equipment_supplier}`), 1)
            ]),
            _: 2
          }, 1032, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "class", "multiple", "collapse-tags", "size", "placeholder", "remote-method", "loading"])
  ]))
}