
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "MaritimeFileSelect",
  props: {
    maritime_file_type_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    datas: {
      type: Array,
      default: [] as any,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:maritime_file_type_id", "changeDatas", "updateMF"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const maritimeFileData = ref([]);
    const isFirst = ref(true);
    const inputMaritimeFileType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isFileTypeRequired = (value) => {
      if (props.required && !value) {
        return t("rmaritimefilestype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "fileType",
      isFileTypeRequired
    );

    const getMaritimeFileType = () => {
      ApiService.query("/api/maritimefiles", {
        params: {
          per_page: 5,
        },
      }).then(({ data }) => {
        inputMaritimeFileType.value.list = data.maritime_files;
        inputMaritimeFileType.value.options = data.maritime_files;
        emit("setMaritimeFiles", data.maritime_files);
        emit("changeDatas", data.maritime_files);
      });
    };
    const selectMaritimeFileType = (query) => {
      if (query !== "") {
        inputMaritimeFileType.value.loading = true;
        setTimeout(() => {
          inputMaritimeFileType.value.loading = false;
          inputMaritimeFileType.value.options =
            inputMaritimeFileType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputMaritimeFileType.value.options.length) {
            ApiService.query(`/api/maritimefiles`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              inputMaritimeFileType.value.list = maritimeFileData.value;
              inputMaritimeFileType.value.options = data.maritime_files;
              emit("setMaritimeFiles", data.maritime_files);
              emit("changeDatas", data.maritime_files);
            });
          }
        }, 200);
      } else {
        inputMaritimeFileType.value.options = maritimeFileData.value;
      }
    };

    watch(
      () => props.maritime_file_type_id,
      (first) => {
        if (
          props.maritime_file_type_id !== undefined &&
          props.maritime_file_type_id !== null
        ) {
          change1Mf(props.maritime_file_type_id);
        }
      },
      { deep: true }
    );

    const change1Mf = (value) => {
      element_id.value = value;
      if (!isFirst.value) {
        emit("updateMF", value);
      } else {
        isFirst.value = false;
      }
    };

    onMounted(() => {
      element_id.value = props.maritime_file_id;
      if (props.mounted) {
        getMaritimeFileType();
      } else {
        inputMaritimeFileType.value.list = props.datas;
        inputMaritimeFileType.value.options = props.datas;
      }
    });

    return {
      props,
      element_id,
      inputMaritimeFileType,
      errorMessage,
      getMaritimeFileType,
      selectMaritimeFileType,
    };
  },
};
