
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "EquipmentsSelect",
  props: {
    equipment_id: {
      type: [Number, Array],
      default: undefined,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:equipment_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const equipmentsData = computed(() => store.getters.Equipments);
    const inputEquipment = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isEquipmentRequired = (value) => {
      if (props.required && !value) {
        return t("requipment");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "equipment",
      isEquipmentRequired
    );

    const getEquipment = () => {
      if (!equipmentsData.value.length) {
        ApiService.query(`/api/equipments/lists`, {
          params: { per_page: 10, name: "" },
        }).then(({ data }) => {
          store.commit("setEquipment", data.equipments);
          inputEquipment.value.list = data.equipments;
          inputEquipment.value.options = data.equipments;
        });
      } else {
        inputEquipment.value.list = equipmentsData.value;
        inputEquipment.value.options = equipmentsData.value;
      }
    };

    const selectEquipment = (query) => {
      if (query !== "") {
        inputEquipment.value.loading = true;
        setTimeout(() => {
          inputEquipment.value.loading = false;
          inputEquipment.value.options = inputEquipment.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputEquipment.value.options.length) {
            ApiService.query(`/api/equipments/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.equipments.forEach((item) => {
                store.commit("addEquipment", item);
              });
              inputEquipment.value.list = equipmentsData.value;
              inputEquipment.value.options = data.equipments;
            });
          }
        }, 200);
      } else {
        inputEquipment.value.options = equipmentsData.value;
      }
    };

    watch(
      () => props.equipment_id,
      (first) => {
        if (first[0].id) {
          element_id.value = first.map((x) => x.id);
        } else {
          element_id.value = first;
        }
      }
    );

    onMounted(() => {
      if (props.mounted) {
        getEquipment();
      } else {
        inputEquipment.value.list = equipmentsData.value;
        inputEquipment.value.options = equipmentsData.value;
      }
    });

    return {
      element_id,
      inputEquipment,
      errorMessage,
      getEquipment,
      selectEquipment,
    };
  },
};
