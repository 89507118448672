
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "MaritimeTripTypesSelect",
  props: {
    trip_type_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:trip_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const tripTypeData = computed(() => store.getters.TripType);
    const inputTripType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isTripTypeRequired = (value) => {
      if (props.required && !value) {
        return t("rtriptype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "tripType",
      isTripTypeRequired
    );

    const getTripType = () => {
      if (props.load && !tripTypeData.value.length) {
        ApiService.get("/api/maritime-trip-types/lists").then(({ data }) => {
          store.commit("setTripType", data.maritime_trip_types);
          inputTripType.value.list = data.maritime_trip_types;
          inputTripType.value.options = data.maritime_trip_types;
        });
      } else {
        inputTripType.value.list = tripTypeData.value;
        inputTripType.value.options = tripTypeData.value;
      }
    };
    const selectTripType = (query) => {
      if (query !== "") {
        inputTripType.value.loading = true;
        setTimeout(() => {
          inputTripType.value.loading = false;
          inputTripType.value.options = inputTripType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputTripType.value.options.length) {
            ApiService.query(`/api/maritime-trip-types/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.maritime_trip_types.forEach((item) => {
                store.commit("addTripType", item);
              });
              inputTripType.value.list = tripTypeData.value;
              inputTripType.value.options = data.maritime_trip_types;
            });
          }
        }, 200);
      } else {
        inputTripType.value.options = tripTypeData.value;
      }
    };

    watch(
      () => props.trip_type_id,
      (first) => {
        element_id.value = first ? first : "";
      }
    );

    onMounted(() => {
      element_id.value = props.trip_type_id ? props.trip_type_id : "";
      if (props.mounted) {
        getTripType();
      } else {
        inputTripType.value.list = tripTypeData.value;
        inputTripType.value.options = tripTypeData.value;
      }
    });

    return {
      element_id,
      inputTripType,
      errorMessage,
      getTripType,
      selectTripType,
    };
  },
};
