
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "OperationTypesSelect",
  props: {
    operation_type_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:operation_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const operationData = computed(() => store.getters.Operation);
    const inputOperationType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isOperationRequired = (value) => {
      if (props.required && !value) {
        return t("roperationtype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "operation",
      isOperationRequired
    );

    const getOperationType = () => {
      if (props.load && !operationData.value.length) {
        ApiService.get("/api/smoperation_types/lists").then(({ data }) => {
          store.commit("setOperation", data.smoperation_types);
          inputOperationType.value.list = data.smoperation_types;
          inputOperationType.value.options = data.smoperation_types;
        });
      } else {
        inputOperationType.value.list = operationData.value.slice();
        inputOperationType.value.options = operationData.value.slice();
      }
    };
    const selectOperationType = (query) => {
      if (query !== "") {
        inputOperationType.value.loading = true;
        setTimeout(() => {
          inputOperationType.value.loading = false;
          inputOperationType.value.options =
            inputOperationType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputOperationType.value.options.length) {
            ApiService.query(`/api/smoperation_types/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.smoperation_types.forEach((item) => {
                store.commit("addOperation", item);
              });
              inputOperationType.value.list = operationData.value;
              inputOperationType.value.options = data.smoperation_types;
            });
          }
        }, 200);
      } else {
        inputOperationType.value.options = operationData.value;
      }
    };

    watch(
      () => props.operation_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      element_id.value = props.operation_type_id;
      if (props.mounted) {
        getOperationType();
      } else {
        inputOperationType.value.list = operationData.value;
        inputOperationType.value.options = operationData.value;
      }
    });

    return {
      element_id,
      inputOperationType,
      errorMessage,
      getOperationType,
      selectOperationType,
    };
  },
};
