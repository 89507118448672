
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "LocationsSelect",
  props: {
    location_id: {
      type: [Number, Array],
      default: undefined,
    },
    location_element: {
      type: Object,
      default: undefined,
    },
    all_locations: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    isMovementContainer: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
    datas: {
      type: Array,
      default: [] as any,
    },
  },
  emits: ["update:location_id", "changeLocation"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const locationsData = computed(() => store.getters.Locations);
    const inputLocation = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isLocationRequired = (value) => {
      if (props.required && !value) {
        return t("rlocation");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "location",
      isLocationRequired
    );

    watch(
      () => props.datas,
      (first) => {
        if (first) {
          inputLocation.value.list = props.datas;
          inputLocation.value.options = props.datas;
        }
      },
      { deep: true }
    );

    const getLocation = () => {
      // eslint-disable-next-line no-empty
      if (locationsData.value.length > 0) {
        let locations = locationsData.value;
        let locations_ids = locations.map((x) => x.id);
        if (
          props.location_element &&
          locations_ids.indexOf(props.location_id) == "-1"
        ) {
          locations.push(props.location_element);
        }
        store.commit("setLocation", locations);
        inputLocation.value.list = locations;
        inputLocation.value.options = locations;
      } else {
        ApiService.get("/api/location/lists").then(({ data }) => {
          let locations = data.locations;
          let locations_ids = locations.map((x) => x.id);
          if (
            props.location_element &&
            locations_ids.indexOf(props.location_id) == "-1"
          ) {
            locations.push(props.location_element);
          }
          store.commit("setLocation", locations);
          inputLocation.value.list = locations;
          inputLocation.value.options = locations;
        });
      }
      const index = inputLocation.value.options.findIndex(
        (x) => x.full_name === "Todos"
      );
      if (props.multiselect) {
        if (index === -1) {
          inputLocation.value.options.splice(0, 0, {
            id: 0,
            code: 0,
            full_name: "Todos",
          });
        }
      } else if (index !== -1) {
        inputLocation.value.options.splice(0, 1);
      }
    };
    const selectLocation = (query) => {
      if (query !== "") {
        inputLocation.value.loading = true;
        setTimeout(() => {
          inputLocation.value.loading = false;
          inputLocation.value.options = inputLocation.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.full_name.toLowerCase().indexOf(query.toLowerCase()) > -1
              );
            }
          );
          if (!inputLocation.value.options.length) {
            let endpoint = "/api/location/lists";
            if (props.all_locations) {
              endpoint += "?name=" + query + "&per_page=25";
            }

            ApiService.get(endpoint).then(({ data }) => {
              data.locations.forEach((item) => {
                store.commit("addLocation", item);
              });
              inputLocation.value.list = locationsData.value;
              inputLocation.value.options = data.locations;
            });
          }
        }, 200);
      } else {
        inputLocation.value.options = locationsData.value;
      }
    };

    watch(
      () => props.location_id,
      (first) => {
        if (first && !props.isMovementContainer) {
          element_id.value = first;
          const selected = inputLocation.value.options.find(
            (x) => x.id === first
          );
          if (selected !== undefined) {
            emit(
              "changeLocation",
              `${selected.full_code} ${selected.full_name}`
            );
          }
        }
        if (
          !inputLocation.value.options.map((x) => x.id).includes(first) &&
          inputLocation.value.options.length > 0 &&
          first !== undefined &&
          first !== null
        ) {
          ApiService.get("/api/location/" + first).then(({ data }) => {
            inputLocation.value.options.push(data);
          });
        }
      }
    );

    onMounted(() => {
      if (props.mounted) {
        getLocation();
      }
      element_id.value = props.location_id;
    });

    return {
      element_id,
      inputLocation,
      errorMessage,
      getLocation,
      selectLocation,
    };
  },
};
