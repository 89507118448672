
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "DispatchesSelect",
  props: {
    dispatch_id: {
      type: [Number, Array],
      default: undefined,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:dispatch_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = undefined as any;
    const dispatchesData = computed(() => store.getters.Dispatches);
    const inputDispatches = ref({
      loading: false,
      options: [],
      list: [],
    });

    const getEquipment = () => {
      if (!dispatchesData.value.length) {
        ApiService.query(`/api/dispatches`, {
          params: { per_page: 25, name: "" },
        }).then(({ data }) => {
          inputDispatches.value.list = data.dispatches;
          inputDispatches.value.options = data.dispatches;
        });
      } else {
        inputDispatches.value.list = dispatchesData.value;
        inputDispatches.value.options = dispatchesData.value;
      }
    };

    const selectEquipment = (query) => {
      if (query !== "") {
        inputDispatches.value.loading = true;
        setTimeout(() => {
          inputDispatches.value.loading = false;
          inputDispatches.value.options = inputDispatches.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputDispatches.value.options.length) {
            ApiService.query(`/api/dispatches`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputDispatches.value.list = data.dispatches;
              inputDispatches.value.options = data.dispatches;
            });
          }
        }, 200);
      } else {
        inputDispatches.value.options = dispatchesData.value;
      }
    };

    watch(
      () => props.dispatch_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      if (props.mounted) {
        getEquipment();
      } else {
        inputDispatches.value.list = dispatchesData.value;
        inputDispatches.value.options = dispatchesData.value;
      }
    });

    return {
      element_id,
      inputDispatches,
      getEquipment,
      selectEquipment,
    };
  },
};
