
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";
import store from "@/store";

export default {
  name: "ShipConsigneeSelect",
  props: {
    entity_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:entity_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref("");
    const entityData = computed(() => store.getters.ShipConsignee);
    const inputEntity = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isEntityRequired = (value) => {
      if (props.required && !value) {
        return t("rentity");
      }
      return true;
    };

    const { errorMessage, value } = useField("entity", isEntityRequired);

    const getEntity = async () => {
      if (props.load && !entityData.value.length) {
        ApiService.query(`/api/entities/lists`, {
          params: {
            per_page: 10,
            name: "",
          },
        }).then(({ data }) => {
          store.commit("setshipConsignee", data.entities);
          inputEntity.value.list = data.entities;
          inputEntity.value.options = data.entities;
        });
      } else {
        inputEntity.value.list = entityData.value;
        inputEntity.value.options = entityData.value;
        let object_entity = undefined as any;
        if (!Array.isArray(props.entity_id)) {
          object_entity = entityData.value.find(
            (x) => x.id === props.entity_id
          );
          if (object_entity === undefined && props.entity_id !== undefined) {
            const { data } = await ApiService.get(
              `/api/entities/${props.entity_id}`
            );
            inputEntity.value.list.push(data);
            inputEntity.value.options.push(data);
          }
        }
      }
    };

    const selectEntity = (query) => {
      if (query !== "") {
        inputEntity.value.loading = true;
        setTimeout(() => {
          inputEntity.value.loading = false;
          inputEntity.value.options = inputEntity.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputEntity.value.options.length) {
            ApiService.query(`/api/entities/lists`, {
              params: {
                per_page: 10,
                name: query,
              },
            }).then(({ data }) => {
              data.entities.forEach((item) => {
                store.commit("addshipConsignee", item);
              });
              inputEntity.value.list = entityData.value;
              inputEntity.value.options = data.entities;
            });
          }
        }, 200);
      } else {
        inputEntity.value.options = entityData.value;
      }
    };

    watch(
      () => props.entity_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      element_id.value = props.entity_id;
      if (props.mounted) {
        getEntity();
      } else {
        inputEntity.value.list = entityData.value;
        inputEntity.value.options = entityData.value;
      }
    });

    return {
      element_id,
      inputEntity,
      errorMessage,
      getEntity,
      selectEntity,
    };
  },
};
