
import { onMounted, ref, watch, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "ShiploadSelect",
  props: {
    shipload_id: {
      type: [Number, Array],
      default: undefined,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "small",
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:shipload_id"],

  setup: function (props) {
    const store = useStore();
    const { t } = useI18n();
    const element_id = ref(null) as any;
    const inputShipload = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const getShipload = () => {
      ApiService.get("/api/shiploads").then(({ data }) => {
        inputShipload.value.list = data.shiploads;
        inputShipload.value.options = data.shiploads;
      });
    };

    const selectShipload = (query) => {
      if (query !== "") {
        inputShipload.value.loading = true;
        setTimeout(() => {
          inputShipload.value.loading = false;
          inputShipload.value.options = inputShipload.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.booking_number.toLowerCase().indexOf(query.toLowerCase()) >
                -1
              );
            }
          );
          if (!inputShipload.value.options.length) {
            ApiService.query(`/api/shiploads`, {
              params: { per_page: 10 },
            }).then(({ data }) => {
              inputShipload.value.list = data.shiploads;
              inputShipload.value.options = data.shiploads;
            });
          }
        }, 200);
      } else {
        inputShipload.value.options = inputShipload.value.list;
      }
    };

    watch(
      () => props.shipload_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      element_id.value = props.shipload_id;
      if (props.mounted) {
        getShipload();
      }
    });

    return {
      element_id,
      inputShipload,
      getShipload,
      selectShipload,
    };
  },
});
