
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "RegisteredEquipmentsSelect",
  props: {
    registered_equipment_id: {
      type: Number,
      default: undefined,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:registered_equipment_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const requipmentsData = computed(() => store.getters.RegisterEquipments);
    const inputEquipment = ref({
      loading: false,
      options: [],
      list: [],
    });

    const isEquipmentRequired = (value) => {
      if (props.required && !value) {
        return t("requipment");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "requipment",
      isEquipmentRequired
    );

    const getREquipment = () => {
      if (!requipmentsData.value.length) {
        ApiService.query(`/api/registered-equipment/lists`, {
          params: { per_page: 10, name: "" },
        }).then(({ data }) => {
          store.commit("setRegisterEquipment", data.registered_equipment);
          inputEquipment.value.list = data.registered_equipment;
          inputEquipment.value.options = data.registered_equipment;
        });
      } else {
        inputEquipment.value.list = requipmentsData.value;
        inputEquipment.value.options = requipmentsData.value;
      }
    };

    const selectREquipment = (query) => {
      if (query !== "") {
        inputEquipment.value.loading = true;
        setTimeout(() => {
          inputEquipment.value.loading = false;
          inputEquipment.value.options = inputEquipment.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.registration_number
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) > -1
              );
            }
          );
          if (!inputEquipment.value.options.length) {
            ApiService.query(`/api/registered-equipment/lists`, {
              params: { per_page: 10, registration_number: query },
            }).then(({ data }) => {
              data.registered_equipment.forEach((item) => {
                store.commit("addRegisterEquipment", item);
              });
              inputEquipment.value.list = requipmentsData.value;
              inputEquipment.value.options = data.registered_equipment;
            });
          }
        }, 200);
      } else {
        inputEquipment.value.options = requipmentsData.value;
      }
    };

    watch(
      () => props.registered_equipment_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      if (props.mounted) {
        getREquipment();
      } else {
        inputEquipment.value.list = requipmentsData.value;
        inputEquipment.value.options = requipmentsData.value;
      }
    });

    return {
      element_id,
      inputEquipment,
      errorMessage,
      getREquipment,
      selectREquipment,
    };
  },
};
