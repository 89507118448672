
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "MaritimeServicesSelect",
  props: {
    maritime_service_id: {
      type: [Number, Array],
      default: undefined,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:maritime_service_id", "createElement", "removeElement"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const maritimeServiceData = computed(() => store.getters.MaritimeService);
    const element_id = ref("");
    const inputMaritimeService = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isServiceRequired = (value) => {
      if (props.required && !value) {
        return t("rservice");
      }
      return true;
    };

    const { errorMessage, value } = useField("service", isServiceRequired);

    const getMaritimeService = () => {
      if (props.load && !maritimeServiceData.value.length) {
        ApiService.get("/api/maritime-services/lists").then(({ data }) => {
          store.commit("setMaritimeService", data.maritime_service);
          inputMaritimeService.value.list = data.maritime_service;
          inputMaritimeService.value.options = data.maritime_service;
        });
      } else {
        inputMaritimeService.value.list = maritimeServiceData.value.slice();
        inputMaritimeService.value.options = maritimeServiceData.value.slice();
      }
    };
    const selectMaritimeService = (query) => {
      if (query !== "") {
        inputMaritimeService.value.loading = true;
        setTimeout(() => {
          inputMaritimeService.value.loading = false;
          inputMaritimeService.value.options =
            inputMaritimeService.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputMaritimeService.value.options.length) {
            ApiService.query(`/api/maritime-service/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.maritime_service.forEach((item) => {
                store.commit("addMaritimeService", item);
              });
              inputMaritimeService.value.list = maritimeServiceData.value;
              inputMaritimeService.value.options = data.maritime_service;
            });
          }
        }, 200);
      } else {
        inputMaritimeService.value.options = maritimeServiceData.value;
      }
    };

    watch(
      () => props.maritime_service_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      element_id.value = props.maritime_service_id;
      if (props.mounted) {
        getMaritimeService();
      } else {
        inputMaritimeService.value.list = maritimeServiceData.value;
        inputMaritimeService.value.options = maritimeServiceData.value;
      }
    });

    return {
      element_id,
      inputMaritimeService,
      errorMessage,
      getMaritimeService,
      selectMaritimeService,
    };
  },
};
