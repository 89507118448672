
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "MaritimeFileTypeSelect",
  props: {
    maritime_file_type_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:maritime_file_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const maritimeFileData = computed(() => store.getters.FileType);
    const inputMaritimeFileType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isFileTypeRequired = (value) => {
      if (props.required && !value) {
        return t("rmaritimefilestype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "fileType",
      isFileTypeRequired
    );

    const getMaritimeFileType = () => {
      if (props.load && !maritimeFileData.value.length) {
        ApiService.get("/api/maritime-file-types/lists").then(({ data }) => {
          store.commit("setFileType", data.maritime_file_types);
          inputMaritimeFileType.value.list = data.maritime_file_types;
          inputMaritimeFileType.value.options = data.maritime_file_types;
        });
      } else {
        inputMaritimeFileType.value.list = maritimeFileData.value;
        inputMaritimeFileType.value.options = maritimeFileData.value;
      }
    };
    const selectMaritimeFileType = (query) => {
      if (query !== "") {
        inputMaritimeFileType.value.loading = true;
        setTimeout(() => {
          inputMaritimeFileType.value.loading = false;
          inputMaritimeFileType.value.options =
            inputMaritimeFileType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputMaritimeFileType.value.options.length) {
            ApiService.query(`/api/maritime-file-types/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.maritime_file_types.forEach((item) => {
                store.commit("addFileType", item);
              });
              inputMaritimeFileType.value.list = maritimeFileData.value;
              inputMaritimeFileType.value.options = data.maritime_file_types;
            });
          }
        }, 200);
      } else {
        inputMaritimeFileType.value.options = maritimeFileData.value;
      }
    };

    watch(
      () => props.maritime_file_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      element_id.value = props.maritime_file_type_id;
      if (props.mounted) {
        getMaritimeFileType();
      } else {
        inputMaritimeFileType.value.list = maritimeFileData.value;
        inputMaritimeFileType.value.options = maritimeFileData.value;
      }
    });

    return {
      props,
      element_id,
      inputMaritimeFileType,
      errorMessage,
      getMaritimeFileType,
      selectMaritimeFileType,
    };
  },
};
