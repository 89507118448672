
import { onMounted, ref, watch, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "BLsAllSelect",
  props: {
    bls_id: {
      type: [Number, Array],
      default: undefined,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "small",
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:bls_id"],

  setup: function (props) {
    const store = useStore();
    const { t } = useI18n();
    const element_id = ref(null) as any;
    const inputBls = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const getBls = () => {
      ApiService.get("/api/bills_of_lading/lists").then(({ data }) => {
        inputBls.value.list = data.bills_of_lading;
        inputBls.value.options = data.bills_of_lading;
      });
    };

    const selectBls = (query) => {
      if (query !== "") {
        inputBls.value.loading = true;
        setTimeout(() => {
          inputBls.value.loading = false;
          inputBls.value.options = inputBls.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.booking_number.toLowerCase().indexOf(query.toLowerCase()) >
                -1
              );
            }
          );
          if (!inputBls.value.options.length) {
            ApiService.query(`/api/bills_of_lading`, {
              params: { per_page: 10 },
            }).then(({ data }) => {
              inputBls.value.list = data.bills_of_lading;
              inputBls.value.options = data.bills_of_lading;
            });
          }
        }, 200);
      } else {
        inputBls.value.options = inputBls.value.list;
      }
    };

    watch(
      () => props.bls_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      if (props.mounted) {
        getBls();
      }
      element_id.value = props.bls_id;
    });

    return {
      element_id,
      inputBls,
      getBls,
      selectBls,
    };
  },
});
