
import { onMounted, ref, watch, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "BookingAllSelect",
  props: {
    booking_id: {
      type: [Number, Array],
      default: undefined,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "small",
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:booking_id"],

  setup: function (props) {
    const store = useStore();
    const { t } = useI18n();
    const element_id = ref(null) as any;
    const inputBooking = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const getBookings = () => {
      ApiService.get("/api/bookings/lists").then(({ data }) => {
        inputBooking.value.list = data.bookings;
        inputBooking.value.options = data.bookings;
      });
    };

    const selectBooking = (query) => {
      if (query !== "") {
        inputBooking.value.loading = true;
        setTimeout(() => {
          inputBooking.value.loading = false;
          inputBooking.value.options = inputBooking.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.booking_number.toLowerCase().indexOf(query.toLowerCase()) >
                -1
              );
            }
          );
          if (!inputBooking.value.options.length) {
            ApiService.query(`/api/bookings`, {
              params: { per_page: 10, booking_number: query },
            }).then(({ data }) => {
              inputBooking.value.list = data.bookings;
              inputBooking.value.options = data.bookings;
            });
          }
        }, 200);
      } else {
        inputBooking.value.options = inputBooking.value.list;
      }
    };

    watch(
      () => props.booking_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      if (props.mounted) {
        getBookings();
      }
      element_id.value = props.booking_id;
    });

    return {
      element_id,
      inputBooking,
      getBookings,
      selectBooking,
    };
  },
});
