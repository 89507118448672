import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_select, {
      modelValue: $setup.element_id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.element_id) = $event)),
      name: "requipment",
      class: _normalizeClass(["custom-select w-100 p-0", !$props.multiselect ? 'chevron' : '']),
      multiple: $props.multiselect,
      "collapse-tags": $props.multiselect,
      size: $props.size,
      clearable: "",
      placeholder: `${_ctx.$t('iselect')}`,
      filterable: "",
      remote: "",
      "remote-show-suffix": "",
      "reserve-keyword": "",
      "remote-method": $setup.selectREquipment,
      loading: $setup.inputEquipment.loading,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:registered_equipment_id', $event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inputEquipment.options, (equipment) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: equipment.id,
            label: `${equipment.id} ${equipment.registration_number}`,
            value: equipment.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(`${equipment.id} ${equipment.registration_number}`), 1)
            ]),
            _: 2
          }, 1032, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "class", "multiple", "collapse-tags", "size", "placeholder", "remote-method", "loading"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString($setup.errorMessage), 1)
  ]))
}